import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SplashScreen from '../components/images/splash-screen';
import LogoIcon from '../components/images/logo-autobahn-network-fff';
import Button from '../components/button';
import FeatureCard from '../components/cards/featureCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCouch, faMapMarkedAlt, faMugHot, faWater } from '@fortawesome/free-solid-svg-icons';

const background = {
  backgroundColor: '#62b7f0',
  top: '0',
  bottom: '137px',
  minHeight: '600px',
  opacity: 0.84,
  position: 'absolute',
  width: '100%',
}

const containerStyle = {
  backgroundColor: '#FFF',
  height: '100vh',
  width: '100%',
};

const contentContainerWrapperStyle = {
  position: 'absolute',
  width: '100%',
  bottom: '280px',
};

const contentContainerStyle = {
  color: 'white',
};

const featuresContainerWrapperStyle = {
  top: '100vh',
  position: 'absolute',
  margin: `0 auto`,
  marginTop: '-210px',
  width: '100%',
};

const navbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  height: '100px',
  padding: '24px',
  position: 'relative',
  textAlign: 'right',
  top: '0',
  margin: '0% auto',
  maxWidth: '920px',
  width: '100%',
  zIndex: 10,
};

const navbarLinkContainerStyle = {
  lineHeight: '48px',
};

const linkStyle = {
  color: '#ffffff',
  cursor: 'pointer',
  padding: '0 12px',
  textDecoration: 'none',
};

const claimStyle = {
  fontSize: '2rem',
  lineHeight: '2.5rem',
};

const NotFoundPage = () => (
  <Layout fluidContainer headerHidden>
    <SEO title="Auszeit Wremen - 404: Not found" />

    <div style={containerStyle}>
      <SplashScreen />
      <div style={background} />
      <div className="d-sm-none text-center pt-4">
        <Link to="/">
          <span style={linkStyle}>Home</span>
          {false && (<LogoIcon />)}
        </Link>
      </div>
      <div className="d-none d-sm-flex" style={navbarContainerStyle}>
        <div>
          <Link to="/">
            <span style={linkStyle}>Home</span>
            {false && (<LogoIcon />)}
          </Link>
        </div>
        <div style={navbarLinkContainerStyle}>
          <Link to="/reservieren"><Button style={{ display: 'inline-block' }}>Reservieren</Button></Link>
        </div>
      </div>
      <div style={contentContainerWrapperStyle}>
        <Container style={{ maxWidth: '920px' }}>
          <Row>
            <Col>
              <div style={contentContainerStyle}>
                <h1 className="intro__title">
                  Erholung pur
                </h1>
                <h2 className="intro__slogan">Nehmen Sie sich<br />eine Auszeit in Wremen</h2>
                <p style={claimStyle}>
                  404: Diese Seite ist nicht verfügbar
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={featuresContainerWrapperStyle}>
        <Container style={{ maxWidth: '920px' }}>
          <Row>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Küste & Meer">
                <FontAwesomeIcon color="#9aa4b3" icon={faWater} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Erholung">
                <FontAwesomeIcon color="#9aa4b3" icon={faMugHot} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Komfort">
                <FontAwesomeIcon color="#9aa4b3" icon={faCouch} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
            <Col xs={6} sm={6} md={3}>
              <FeatureCard label="Top Lage">
                <FontAwesomeIcon color="#9aa4b3" icon={faMapMarkedAlt} style={{ height: '80px' }} size="3x" />
              </FeatureCard>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <br /><br />
  </Layout>
)

export default NotFoundPage
